<template>
  <div>
    <!-- 顶部 -->
    <top></top>
    <!-- 个人中心头部 -->
    <div class="a">
      <div class="personal_box">
        <div :class="{ personala: aaa, personalb: bbb }">
          <!-- 个人中心的框 -->
          <div class="personal">
            <!-- 个人中心内容 -->
            <div class="personal_content">
              <!-- 左边 -->

              <div class="personal_left">
                <!-- 头像 -->
                <div class="head_image">
                  <img :src="useraimg" alt="" />
                </div>
              </div>
              <!-- 中间 -->
              <div class="personal_middle">
                <!-- 上 -->
                <div class="personal_middle_top">
                  <p>
                    <span style="margin-right: 10px;">{{
                      changeusername
                    }}</span>
                    <span style="margin-right: 10px;"> {{ dellphone }}</span>
                  </p>
                  <button @click="entrance" v-show="isLogin">
                    <img src="../assets/home/186.png" />修改资料
                  </button>
                  <button style="margin-left: 20px;" @click="quit" v-show="isLogin">
                    退出登录
                  </button>
                </div>
                <!-- 下 -->
                <div class="personal_middle_bottom">
                  <p>
                    <img src="../assets/home/蒙版组 187.png" v-show="vipnumber" />
                    <img src="../assets/home/35.png" v-show="!vipnumber" />
                    {{ useravip }}
                  </p>
                  <!-- <p><img src="../assets/home/35.png" v-show="!vipnumber" />{{useravip}}</p> -->
                  <p>|</p>
                  <p @click="rights">查看会员权益</p>
                </div>



                 <!-- 复制邀请链接。。。。。。。。。。。 -->
                 <div class="invitationbox" v-show="isagent==1?true:false">
                  <div class="identifying" >
                    <img src="../assets/aaa/dai.png" alt="">
                  </div>
                  <div class="copay_link"  v-show="isagent==1?true:false" @click="copy_url(copy_Urler)">
                    <p>复制邀请链接</p>
                  </div>
                  <div class="me_team" @click="gomyteam" >
                    <p>进入我的团队</p>
                  </div>
                </div>
              </div>
              <!-- 右边 -->
              <div class="personal_right">
                <!-- 上 -->
                <div class="personal_right_top">
                  <button @click="member">
                    <h4>{{ renew }}</h4>
                  </button>
                </div>
                <!-- 下 -->
                <div class="personal_right_bottom">
                  <p v-show="liberal" v-if="isLogin">您的会员服务还有{{ membertime }}天到期</p>
                  <p></p>
                  <p v-show="!liberal">开通会员、享1V1客服服务</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- 代理申请入口 -->
    <!-- <div class="agentbox">
      <div class="agentimg" @click="go_apply" >
      <img src="../assets/aaa/agentimg.png" alt="">
    </div>
    </div> -->
    <!-- 选项卡 -->
    <!-- <div class="content_box">
      <div class="content">
        <div class="tab">
          <ul>
            <li v-for="(item, index) in btns" :key="index" :class="activeIndex == index ? 'btnAtive' : ''"
              @click="handleToggle(index)">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    

    <!-- 代理申请 -->
    <div class="agent_container">
      <!-- title -->
      <div class="agent_title">
          <div class="agent_headbox">
            <div class="agent_img">
              <img src="../assets/aaa/agent_pacter.png" alt="">
            </div>
            <div class="agent_fontext">
              <p>代理申请</p>
            </div>
          </div>
          <div class="agetn_english">
            <p>Agency application</p>
          </div>
      </div>
      <div class="agent_infobox">
        <ul class="goods_agentbox">
          <li class="list_agentinfo" v-for="(item, index) in agentinfo" :key="index">
            <div class="agentinfoimg">
              <img :src="item.img" alt="">
            </div>
             <div class="agentinfo_title">
               <p>{{item.remark}}</p>
             </div>
             <div class="agentinfo">
               <p>{{item.content}}</p>
             </div>
          </li>


       
          

          
        </ul>
      </div>

      <div class="away_agentbtn" @click="agetn_sucesbtn">
        <p>立即申请</p>
      </div>
    </div>


    <!-- 申请成功 -->
    <div class="agent_success" v-show="show_success" @click="showbackbox(event)">
       <div class="autoinfobx">
          <div class="errimgbtn" @click="errbtn">
            <img src="../assets/aaa/errorimg.png" alt="">
          </div>
          <div class="successimg">
            <img src="../assets/aaa/queryimg.png" alt="">
          </div>
          <div class="fontbidtext">
            <p>恭喜您提交成功</p>
            <p>请耐心等待系统审核</p>
          </div>
       </div>
    </div>
    


    

    <!-- 广告 -->
    <div class="advertising_box" v-show="isShow">
      <div class="advertising">
        <button class="occlude" @click="onclusa">×</button>
        <!-- 图片 -->
        <div class="advertising_image">
          <i><img src="../assets/home/vip.png" alt="" /></i>
        </div>
        <!-- 按钮 -->
        <div class="advertising_button">
          <button @click="kaitong">开通VIP</button>
        </div>
      </div>
    </div>
    <!-- 侧边栏 -->
    <sidebar></sidebar>
    <!-- 底部 -->
    <bottom></bottom>>
    <!-- <div>
      <img src="../assets/customization/页面-1.png" alt="">
    </div> -->
  </div>
</template>
<script>
    // import {
    //     getPersinalcollect
    // } from "../http.js";
    import Qs from "qs";
    import axios from "axios";
    export default {
        data() {
            return {
                show_success: false,
                changeusername: "游客状态",
                dellphone: "(135XXXXX666)",
                useraimg: require("../assets/home/1.png"),
                useravip: "会员",
                uiduid: "",
                openida: "",
                favorite: [],
                shoucang: [],
                fuli: [],
                dingdan: [],
                renew: "会员充值",
                aaa: true,
                bbb: true,
                substantial: true,
                dredge: true,
                orderform: true,
                zhuangtai: "",
                substant: "",
                keyabc: [],
                membertime: "",
                pagaac: "",
                count: 0,
                vipnumber: true,
                liberal: true,
                valuea: {},
                isShow: false, //vip弹窗显隐
                isHaveData: true,
                current_page: 1,
                all: '',
                isagent: "",
                tellphone: "",
                // 好处
                agentinfo: [],
                login_key: "",
                copy_Urler: "",
                // 判断登录状态 显示修改资料 退出登录
                isLogin: 1

            };
        },
        mounted() {
            var key = JSON.parse(window.sessionStorage.getItem("key"))
            if (key) {
                this.copy_Urler = JSON.parse(window.sessionStorage.getItem("key")).invitation;
                // this.collecta();
                // this.benefits();
                // this.ordera();

                this.tellphone = JSON.parse(window.sessionStorage.getItem("key")).telephone;
                this.uiduid = JSON.parse(window.sessionStorage.getItem("key")).uid;
                this.login_key = JSON.parse(window.sessionStorage.getItem("key")).openid;
                this.$axios({
                    method: "post",
                    url: "/api/shopmini/wap_IsAgent.html",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: Qs.stringify({
                        uid: this.uiduid,
                        login_key: this.login_key,
                        tel: this.telephone
                    }),
                }).then((res) => {
                    this.isagent = res.data.data
                });
            } else {
                this.isLogin = 0
                    // 
                    // 
                    // 
                    // 
                    // 
            }


        },
        created() {
            this.$axios({
                    method: "post",
                    url: "/api/shopmini/wap_Agentgoods.html",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    }
                })
                .then((res) => {
                    console.log(res);
                    this.agentinfo = res.data.data;
                })
                .catch(function(error) {
                    console.log(error);
                });
            if (this.$route.query.count == 1) {
                this.activeIndex = 1
            }
            //从sessionStorage中获取用户的类型
            this.valuea = JSON.parse(sessionStorage.getItem("key"));
            // this.pagaac = this.$route.query.pagaac;
            // if (this.pagaac == 0) {
            //   this.activeIndex = 0;
            // } else if (this.pagaac == 1) {
            //   this.activeIndex = 1;
            // } else if (this.pagaac == 2) {
            //   this.activeIndex = 2;
            // }
            //从sessionStorage中获取用户的类型
            var infostr = sessionStorage.getItem("key");
            if (infostr) {
                var keyaaa = JSON.parse(infostr);
                this.keyabc = keyaaa;
                this.membertime = this.keyabc.membertime;

                // console.log(this.membertime);
                window.sessionStorage.setItem(this.keyabc, this.membertime);
            }
            if (this.keyabc.uid == "") {
                console.log(1);
            } else {
                console.log(2);
                // 个人信息
                if (keyaaa) {
                    this.changeusername = keyaaa.realname;
                    this.dellphone = "(" + keyaaa.phone + ")";
                    this.useraimg = keyaaa.headimgurl;
                    // this.useravip = keyaaa.class;

                    this.uiduid = keyaaa.uid;
                    this.openida = keyaaa.openid;
                }
                if (keyaaa.class == 0) {
                    this.useravip = "暂未开通会员";
                    this.renew = "会员充值";
                    // 顶部背景图显隐
                    this.aaa = false;
                    this.bbb = true;
                    this.dredge = false;
                    // 会员图标
                    this.vipnumber = true;
                    // 会员充值
                    this.liberal = false;
                } else if (keyaaa.class == 1) {
                    this.useravip = "会员等级：" + "月度会员";
                    this.renew = "会员续费";
                    this.aaa = true;
                    this.bbb = false;
                    this.dredge = true;
                    // 会员图标
                    this.vipnumber = false;
                    // 会员充值
                    this.liberal = true;
                } else if (keyaaa.class == 2) {
                    this.useravip = "会员等级：" + "季度会员";
                    this.renew = "会员续费";
                    this.aaa = true;
                    this.bbb = false;
                    this.dredge = true;
                    // 会员图标
                    this.vipnumber = false;
                    // 会员充值
                    this.liberal = true;
                } else if (keyaaa.class == 3) {
                    this.useravip = "会员等级：" + "年度会员";
                    this.renew = "会员续费";
                    this.aaa = true;
                    this.bbb = false;
                    this.dredge = true;
                    // 会员图标
                    this.vipnumber = false;
                    // 会员充值
                    this.liberal = true;
                } else {
                    console.log(111);
                }
            }

        },
        methods: {
            // 去我的团队
            gomyteam() {
                this.$router.push({
                    name: "myteam"
                })
            },


            copy_url(shareLink) {
                var input = document.createElement("input"); // 直接构建input
                input.value = shareLink; // 设置内容
                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$message({
                    message: "复制成功",
                    type: 'success'
                })
            },

            // 立即申请btn
            agetn_sucesbtn() {
                this.$router.push({
                        name: ''
                    }),
                    this.$axios({
                        method: "post",
                        url: "/api/shopmini/wap_Agent.html",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: Qs.stringify({
                            uid: this.uiduid,
                            login_key: this.openida,
                            tel: this.tellphone,
                        }),
                    })
                    .then((res) => {
                        if (res.data.code != "1") {
                            this.$message({
                                message: res.data.msg,
                                type: 'success'
                            });
                            // this.$router.push({
                            //     name: "loginM",
                            // })
                        } else {
                            this.show_success = true
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });



            },




            // 申请成功右上角XX
            errbtn(e) {
                this.show_success = false
                e.cancelBubble = true;
                // event.stopPropagation()

            },
            showbackbox(e) {
                // e.cancelBubble = true;
                this.show_success = false
            },


            // 开通vip弹窗确定按钮
            kaitong() {
                this.$router.push({
                    path: "/vip",
                });
            },
            // 关闭vip弹窗
            onclusa() {
                this.isShow = false;
            },



            // 修改资料按钮
            entrance() {
                this.$router.push("/amend");
            },

            // 会员续费按钮
            member() {
                this.$router.push("/Vip");
            },
            // 退出按钮
            quit() {
                sessionStorage.clear(this.keyabc);
                this.$router.push("/index");
                history.go(0)
            },
            // 分页
            handleSizeChange(val) {
                this.current_page = val;
                console.log(`每页 ${val} 条`);
                this.collecta(val, this.tag);
            },
            handleCurrentChange(val) {
                this.current_page = val;
                this.collecta(val, this.tag);
                console.log(`当前页: ${val}`);
                // console.log(111);
            },
            // 查看会员权益按钮
            rights() {
                this.$router.push({
                    name: "Vip",
                });
            },
        },
    };
</script>
<style scoped>
    /* agent_success */
    
    .agent_success {
        top: 0;
        width: 100%;
        z-index: 9999;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
    }
    
    .agent_success .autoinfobx {
        width: 420px;
        border-radius: 12px;
        padding: 16px 10px;
        margin: auto;
        background-color: #fff;
        display: flex;
        flex-direction: column;
    }
    
    .agent_success .autoinfobx .errimgbtn {
        display: flex;
        flex-direction: row-reverse;
    }
    
    .agent_success .autoinfobx .errimgbtn img {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }
    
    .agent_success .autoinfobx .errimgbtn img:hover {
        transform: rotate(360deg);
        transition-duration: 0.5s;
    }
    
    .agent_success .autoinfobx .successimg {
        display: flex;
        margin: 34px 0 30px;
    }
    
    .agent_success .autoinfobx .successimg img {
        margin: auto;
        width: 55px;
        height: 55px;
    }
    
    .agent_success .autoinfobx .fontbidtext {
        margin: auto;
        padding: 0 0 50px;
    }
    
    .agent_success .autoinfobx .fontbidtext p:nth-child(1) {
        font-size: 24px;
        font-family: MicrosoftYaqiHei, MicrosoftYaqiHei-Bold;
        text-align: left;
        color: #f6295a;
        line-height: 32px;
        letter-spacing: 6px;
    }
    
    .agent_success .autoinfobx .fontbidtext p:nth-child(2) {
        margin: auto;
        text-align: center;
        font-size: 16px;
        font-family: MicrosoftYaqiHei, MicrosoftYaqiHei-Bold;
        font-weight: 500;
        color: #f6295a;
        line-height: 25px;
    }
    /* 代理申请内容区 */
    
    .agent_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }
    
    .agent_container .agent_title {
        width: 1200px;
        margin: auto;
        display: flex;
        flex-direction: column;
    }
    
    .agent_container .agent_title .agent_headbox {
        display: flex;
        margin: auto;
    }
    
    .agent_container .agent_title .agent_headbox .agent_img {
        width: 40px;
        height: 40px;
        display: flex;
        margin-top: 6px;
        margin-right: 8px;
    }
    
    .agent_container .agent_title .agent_headbox .agent_img img {
        width: 90%;
        height: 90%;
        margin: auto;
    }
    
    .agent_container .agent_title .agent_headbox .agent_fontext {
        display: flex;
    }
    
    .agent_container .agent_title .agent_headbox .agent_fontext p {
        margin: auto;
        font-size: 33px;
        font-family: MicrosoftYaqiHei, MicrosoftYaqiHei-Bold;
        font-weight: 700;
        text-align: center;
        line-height: 24px;
        letter-spacing: 2px;
        color: #222222;
    }
    
    .agent_container .agent_title .agetn_english {
        margin: auto;
    }
    
    .agent_container .agent_title .agetn_english p {
        font-size: 12px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: center;
        color: #222222;
        line-height: 17px;
    }
    /* 代理的好处 */
    
    .agent_container .agent_infobox {
        display: flex;
        width: 1200px;
        margin: auto;
    }
    
    .agent_container .agent_infobox .goods_agentbox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    
    .agent_container .agent_infobox .goods_agentbox .list_agentinfo {
        width: 26%;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 13px;
        margin: 40px 43px;
        transition-duration: 0.3s;
    }
    
    .agent_container .agent_infobox .goods_agentbox .list_agentinfo:hover {
        transform: scale(1.03);
        box-shadow: 0px 0px 20px 0px rgb(246 41 89 / 15%);
    }
    
    .agent_container .agent_infobox .goods_agentbox .list_agentinfo .agentinfoimg {
        width: 100%;
        display: flex;
        margin: 32px 0 44px;
    }
    
    .agent_container .agent_infobox .goods_agentbox .list_agentinfo .agentinfoimg img {
        width: 122px;
        height: 122px;
        margin: auto;
    }
    
    .agent_container .agent_infobox .goods_agentbox .list_agentinfo:hover .agentinfoimg img {}
    
    .agent_container .agent_infobox .goods_agentbox .list_agentinfo .agentinfo_title {
        display: flex;
        margin: 32px 0;
    }
    
    .agent_container .agent_infobox .goods_agentbox .list_agentinfo .agentinfo_title p {
        margin: auto;
        font-size: 24px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: center;
        color: #222222;
        line-height: 31px;
    }
    
    .agent_container .agent_infobox .goods_agentbox .list_agentinfo .agentinfo {
        margin: 0 6% 44px;
    }
    
    .agent_container .agent_infobox .goods_agentbox .list_agentinfo .agentinfo p {
        font-size: 14px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #000000;
        line-height: 22px;
    }
    
    .agent_container .away_agentbtn {
        margin: 0 auto 50px;
        background: #f6295a;
        border-radius: 28px;
        padding: 10px 100px;
        cursor: pointer;
    }
    
    .agent_container .away_agentbtn p {
        /* border: 1px solid red; */
        font-size: 24px;
        font-family: MicrosoftYaqiHei, MicrosoftYaqiHei-Bold;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
    }
    /*   <!-- 复制邀请链接。。。。。。。。。。。 -->  */
    
    .invitationbox {
        width: 100%;
        display: flex;
        margin-top: 15px;
    }
    
    .invitationbox .identifying {
        width: 21px;
        height: 23px;
        margin: auto 10px auto 0;
        display: flex;
    }
    
    .invitationbox .identifying img {
        width: 21px;
        height: 21px;
        margin: auto;
    }
    
    .invitationbox .copay_link {
        background-color: #F62959;
        display: flex;
        border-radius: 11px;
        cursor: pointer;
        margin-right: 15px;
    }
    
    .invitationbox .copay_link p {
        color: #fff;
        line-height: 23px;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        margin: auto;
        padding: 1px 16px;
    }
    
    .invitationbox .me_team {
        background-color: #F62959;
        display: flex;
        border-radius: 11px;
        cursor: pointer;
    }
    
    .invitationbox .me_team p {
        color: #fff;
        line-height: 23px;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        margin: auto;
        padding: 0 16px;
    }
    /* agent */
    
    .agentbox {
        width: 100%;
        display: flex;
        margin-top: 18px;
    }
    
    .agentbox .agentimg {
        width: 1200px;
        margin: auto;
        cursor: pointer;
    }
    
    .agentbox .agentimg img {
        width: 100%;
        height: 100%;
        display: block;
    }
    /* 广告 */
    
    .advertising_box {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        position: fixed;
        top: 0;
        /* display: none; */
    }
    
    .advertising {
        width: 600px;
        height: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -300px;
        margin-left: -300px;
        /* background-color: red; */
    }
    
    .advertising_image {
        width: 600px;
        height: 490px;
    }
    
    .advertising_image img {
        width: 600px;
        height: 490px;
    }
    /* 按钮 */
    
    .advertising_button {
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .advertising_button button {
        width: 150px;
        height: 50px;
        background: linear-gradient(90deg, #eac583, #dca545);
        border-radius: 25px;
        box-shadow: 0px 3px 6px 0px rgba(159, 116, 68, 0.1);
        font-size: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
    }
    
    .occlude {
        font-size: 25px;
        line-height: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        right: 50px;
        top: 80px;
    }
    /* 广告结束 */
    
    .a {
        padding-top: 60px;
    }
    
    .personal_box {
        width: 100%;
        min-width: 1200px;
        height: 200px;
    }
    
    .personala {
        background-image: url("../assets/home/56.png");
        background-repeat: no-repeat;
        display: block;
    }
    
    .personalb {
        background-image: url("../assets/home/196.png");
        background-repeat: no-repeat;
        display: block;
    }
    
    .personal {
        width: 1200px;
        height: 200px;
        margin: auto;
    }
    
    .personal_content {
        width: 94%;
        margin: auto;
        display: flex;
        align-items: center;
    }
    /* 左边 */
    
    .personal_left {
        width: 130px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* 头像 */
    
    .head_image {
        width: 130px;
        height: 130px;
        border: 2px white solid;
        border-radius: 50%;
        /* border: 1px red solid; */
        overflow: hidden;
    }
    
    .head_image img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
    /* 中间 */
    
    .personal_middle {
        width: 60%;
        margin-left: 30px;
    }
    /* 上 */
    
    .personal_middle_top {
        height: 32px;
        font-size: 20px;
        font-weight: bold;
        color: white;
        display: flex;
    }
    
    .personal_middle_top>button {
        width: 90px;
        height: 30px;
        background-color: #f62959;
        border-radius: 15px;
        color: white;
    }
    
    .personal_middle_top>button img {
        vertical-align: middle;
        margin-right: 4px;
        width: 16px;
        height: 16px;
    }
    /* 下 */
    
    .personal_middle_bottom {
        margin-top: 5px;
        display: flex;
        color: #ffffff;
        font-size: 14px;
    }
    
    .personal_middle_bottom>p:nth-of-type(1) {
        margin-left: 0;
        white-space: nowrap;
    }
    
    .personal_middle_bottom>p:nth-of-type(2) {
        width: 0;
    }
    
    .personal_middle_bottom>p:nth-of-type(3) {
        cursor: pointer;
        width: 0;
        white-space: nowrap;
    }
    
    .personal_middle_bottom>p {
        margin-left: 10px;
    }
    
    .personal_middle_bottom>p>img {
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-right: 4px;
        margin-top: -5px;
    }
    /* 右边 */
    
    .personal_right {
        width: 30%;
    }
    
    .personal_right>div {
        display: flex;
        justify-content: flex-end;
    }
    
    .personal_right>div>button {
        width: 131px;
        height: 50px;
        font-size: 16px;
        color: #262525;
        background: #fec675;
        border-radius: 25px;
    }
    
    .personal_right>div>p {
        line-height: 20px;
        font-size: 14px;
        color: #fec675;
        margin-top: 12px;
    }
    /* 内容 */
    
    .content_box {
        width: 100%;
        min-width: 1200px;
    }
    
    .content {
        width: 1200px;
        margin: auto;
    }
    /* 选项卡 */
    /* .tab {} */
    
    .tab>ul {
        display: flex;
        justify-content: center;
    }
    
    .tab>ul>li {
        width: 100px;
        line-height: 60px;
        cursor: pointer;
        text-align: center;
    }
    
    .btnAtive {
        color: #f62959;
        border-bottom: 3px #f62959 solid;
    }
    /* 内容 */
    
    .container>div {
        width: 1192px;
        /* width: 67%; */
        margin: 0 auto;
        /* background-color: red; */
        min-height: 300px;
        /* max-height: 1300px; */
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }
    
    .box {
        /* margin: 0 auto; */
        /* background-color: red; */
        display: flex;
        flex-wrap: wrap;
        /* display: block; */
    }
    /* 无内容时 */
    
    .boxa {
        /* background-color: tan; */
        margin: 0 auto;
        margin-bottom: 50px;
        /* display: block; */
    }
    
    .boxa img {
        width: 300px;
        height: 300px;
        /* margin: 0 auto; */
    }
    
    .boxa p {
        letter-spacing: 2px;
        font-size: 20px;
        font-weight: 400;
        /* color: red; */
        text-align: center;
    }
    /* 选项卡内容 */
    
    .tab-1 {
        /* margin: 20px 22px 20px 22px; */
        margin-bottom: 20px;
        padding-left: 10px;
        padding-top: 2px;
        width: 380px;
        border-radius: 8px;
        /* background-color: green; */
        background-color: #ffffff;
        cursor: pointer;
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.08);
        box-sizing: border-box;
        transition: 0.3s;
        /* display: flex;
        flex-wrap: wrap; */
    }
    
    .tab-1:not(:nth-child(3n)) {
        margin-right: 20px;
    }
    
    .tab-1:hover {
        transform: scale(1.03);
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.15);
    }
    
    .tab-title>p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .tab-title>p:hover {
        color: #f62959;
    }
    
    .tab_image {
        width: 100%;
        height: 160px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .tab_image img {
        width: 100%;
        height: 160px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .tab-title-font {
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-top: 10px;
    }
    
    .tab-one {
        width: 6px;
        height: 22px;
        border-radius: 25px;
        vertical-align: middle;
        margin: 0px 10px 0px 10px;
        background-color: #f62959;
    }
    
    .tab-title {
        vertical-align: middle;
        font-size: 20px;
        color: #333333;
    }
    /* 文字内容 */
    
    .tab-font {
        width: 86%;
        height: 100px;
        padding-top: 20px;
        /* background-color: red; */
        margin: auto;
        font-size: 14px;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    /* 标签 */
    
    .label {
        width: 86%;
        /* height: 30px; */
        min-height: 30px;
        max-height: 70px;
        /* background-color: tan; */
        margin: auto;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px #fee9ee solid;
    }
    
    .label div {
        padding: 3px 8px 3px 8px;
        display: inline-block;
        background-color: #fff7f2;
        color: #ff5c0d;
        border-radius: 2px;
        margin: 4px;
    }
    /* 个人信息 */
    
    .personal_one {
        width: 86%;
        margin: auto;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666666;
    }
    
    .personal_one p:nth-of-type(1) {
        margin: 0px 10px 0px 10px;
    }
    
    .personal_one img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
    
    .personal_one p:nth-of-type(2) {
        width: 35%;
        /* background-color: red; */
    }
    
    .personal_one p:nth-of-type(3) {
        width: 50%;
        text-align: right;
        /* background-color: tan; */
    }
    /* 有图的 */
    
    .tab_2 {
        padding: 0;
    }
    /* 没会员 */
    
    .dredgeine {
        width: 100%;
        height: 300px;
        margin: 0 auto;
        /* background-color: red; */
    }
    
    .dredgeine p {
        line-height: 300px;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
    }
    /* 有订单 */
    
    .tablea {
        margin-top: 20px;
        margin-bottom: 50px;
    }
    /* 没订单 */
    
    .inquiry {
        margin: 0 auto;
        /* background-color: tan; */
        margin-bottom: 50px;
    }
    
    .inquiry img {
        width: 300px;
        height: 300px;
        /* margin: 0 auto; */
    }
    
    .inquiry p {
        letter-spacing: 2px;
        font-size: 20px;
        font-weight: 400;
        /* color: red; */
        text-align: center;
    }
    /* 底部信息 */
    
    .tab_content_box {
        /* background-color: tan; */
        padding-left: 10px;
        color: #333333;
        line-height: 32px;
        margin-top: 10px;
    }
    
    .tab_content_bottom {
        display: flex;
        font-size: 14px;
    }
    
    .tab_content_top:hover {
        color: #f62959;
    }
    
    .tab_content_left {
        width: 58%;
        /* background-color: red; */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .tab_content_right {
        width: 40%;
        /* background-color: tan; */
        text-align: right;
    }
    /* 我的订单 */
    /* 表格 */
    
    table {
        width: 100%;
        border-collapse: collapse;
        background-color: #f0f3f5;
        color: #333333;
    }
    
    table td {
        line-height: 60px;
        text-indent: 20px;
        font-size: 16px;
    }
    /* 表格第一行 */
    
    .table_one {
        background-color: #ebebeb;
    }
    
    .table_one td {
        color: #666666;
        background-color: #ebebeb;
        line-height: 44px;
    }
    
    .table_one td:nth-of-type(1) {
        width: 315px;
    }
    
    .table_one td:nth-of-type(2) {
        width: 220px;
    }
    
    .table_one td:nth-of-type(3) {
        width: 350px;
    }
    
    .table_one td:nth-of-type(4) {
        width: 180px;
    }
    
    .table_one td:nth-of-type(5) {
        width: 180px;
    }
    
    .paging {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .el-pager li {
        font-size: 12px;
        width: auto !important;
        height: auto !important;
    }
    
    .active {
        border: none !important;
        border-radius: 50% !important;
        background-color: #f62959 !important;
    }
    
    .number:hover {
        color: #fff !important;
        background-color: #f62959 !important;
    }
    
    .number {
        border-radius: 50% !important;
        border: 1px #999 solid !important;
    }
    
    .btn-prev {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-prev:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    
    .btn-prev>i::before {
        content: "上一页" !important;
        font-size: 14px;
        font-family: PingFang HK, PingFang HK-Medium !important;
    }
    
    .btn-next {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-next:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    /* 下一页 */
    
    .btn-next>i::before {
        content: "下一页" !important;
    }
</style>
<style>
    .el-pager li {
        font-size: 12px;
        width: auto !important;
        height: auto !important;
    }
    
    .active {
        border: none !important;
        border-radius: 50% !important;
        background-color: #f62959 !important;
    }
    
    .number:hover {
        color: #fff !important;
        background-color: #f62959 !important;
    }
    
    .number {
        border-radius: 50% !important;
        border: 1px #999 solid !important;
    }
    
    .btn-prev {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-prev:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    
    .btn-prev>i::before {
        content: "上一页" !important;
        font-size: 14px;
        font-family: PingFang HK, PingFang HK-Medium !important;
    }
    
    .btn-next {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-next:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    /* 下一页 */
    
    .btn-next>i::before {
        content: "下一页" !important;
    }
</style>